import '../../styles/pages/spring-2020/spring-2020-index.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet";
import Link from "gatsby-plugin-transition-link/AniLink";
import { Parallax, Background } from 'react-parallax';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ScrollAnimation from 'react-animate-on-scroll';
import {spring2020stories} from '../../components/spring-2020-stories/stories';
import { withPrefix } from 'gatsby'
import socialBanner from "../../images/spring-2020/social-banners/main.jpg";

// import cover from '../images/spring-2020/cover2.jpg';

import sweetPotatoTitle from '../../images/spring-2020/lessons-sweet-potato/title-colored.png';
import ArrowRightCircle from '../../images/icons/arrow-right-circle.svg'
import ArrowDownCircle from '../../images/icons/arrow-down-circle.svg'

import digitalTitanMagVideoThumb from '../../images/spring-2020/digital-titan-mag-video-thumb.jpg';

import ITAT from '../../images/spring-2020/campaign-logo-horiz-with-tag-white.svg'
import TitanVoices from '../../images/spring-2020/titan-voices.svg'


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Titan: Sping 2020';
    var pageDesc = 'TITAN, the signature magazine of Cal State Fullerton, is proud to premiere its first fully digital issue. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan.';


    // var heroStyles = {
    //   backgroundColor: '#222',
    //   backgroundImage: `url(${cover})`,
    //   backgroundRepeat: 'no-repeat',
    //   backgroundPosition: 'center center',
    //   backgroundSize: 'cover',
    //   color: 'white',
    //   height: '1200px'
    // }

    var videoStyles = {
      width: '100%',
      height: '600px',
      bottom: '0',
      objectFit: 'cover'
    }

    const list = spring2020stories.map(function(story){
      if (story.slug != 'one-pitch-at-a-time' && story.slug != 'digital-titan-mag') {

        var url = "/spring-2020/" + story.slug + "/";
        var classname = "story-link " + story.storyType + " " + story.slug;

        var linkStyle = {
          backgroundImage: `url(${story.thumbnailIndex})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }

        var title = <h3>{story.title}</h3>

        if (story.slug == 'lessons-sweet-potato') {
          title = <h3><img src={sweetPotatoTitle} alt="Lessons From the Lowly Sweet Potato" /></h3>
        }

        if (story.slug == 'boundless-opportunity') {
          title = <h3><span className="first-word">Boundless</span> <span className="second-word">Opportunity</span></h3>
        }

        var subtitle = null;
        if (story.subtitle && story.subtitle != '') {
          subtitle = <span className="subtitle">{story.subtitle}</span>
        }

        var video = null;
        if (story.slug == 'arboretum-beehive-research') {
          var video = 
          <video style={videoStyles} id="heroVideo" autoPlay="autoplay" loop muted="muted" poster="https://news.fullerton.edu/_resources/titanmag/video/bee-farm.jpg" title="Bee Hive">
            <source src="https://news.fullerton.edu/_resources/titanmag/video/bee-farm.webm" type="video/webm; codecs=vp9,vorbis" />
            <source src="https://news.fullerton.edu/_resources/titanmag/video/bee-farm.mp4" type="video/mp4" />
            <track kind="descriptions"  src="https://news.fullerton.edu/_resources/titanmag/video/bee-farm.html" />
          </video>
        }


        return (
          <Link
          style={linkStyle}
          className={classname}
          paintDrip
          hex="#FF7900"
          duration={.75}
          to={url}>
            <div className="story-text">
              {title}
              {subtitle}
            </div>
          </Link>
        )
      }
    })

    var introStory = spring2020stories[7];

    return (
      <Layout locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <body className="spring-2020-index" />

          <title>{pageTitle}</title>

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

        </Helmet>
        <h1 style={{display: 'none'}}>Titan: Sping 2020</h1>

        {/* <Parallax
        bgImage={cover}
        bgImageAlt=""
        strength={300}
        style={{margin: '0'}}
        className="full-width parallax-hero animated fadeIn slower"> */}
        <div className="full-width hero animated fadeIn slower">
          <div className="hero-text">
            <h2 className="animated fadeInDown">One Pitch at a Time</h2>
            <p className="sub-heading animated fadeInDown delay-1s">From Goodwin Field to the Big Leagues, Titan Baseball Hits It Out of the Park</p>
            <Link
            className="button2 animated fadeInDown delay-2s"
            paintDrip
            hex="#FF7900"
            duration={.75}
            to="/spring-2020/one-pitch-at-a-time/">
              Read Story <img src={ArrowRightCircle} alt="" className="icon" />
            </Link>
            <AnchorLink href='#intro' className="button2 more-stories-button animated fadeInDown delay-2s">
              More Stories <img src={ArrowDownCircle} alt="" className="icon" />
            </AnchorLink>
          </div>
          <div className="slant" />
          <div className="tint" />
        </div>
        {/* </Parallax> */}

        <section id="intro">
          <h2 className="center"><em>In This Issue:</em></h2>

          <div className="wrap story-list">
            {/* <div className="row introStory">
              <div className="col-4 introStory-image">
                <Link
                  className='image-link'
                  paintDrip
                  hex="#FF7900"
                  duration={.75}
                  to={'/spring-2020/' + introStory.slug}>
                <img src={digitalTitanMagVideoThumb} alt="Read Story" />
                </Link>
              </div>
              <div className="col-8 introStory-text">

                <h3>
                  <Link
                  className='heading-link'
                  paintDrip
                  hex="#FF7900"
                  duration={.75}
                  to={'/spring-2020/' + introStory.slug}>
                      Lorem Ipsum Dolor Sit Amet
                  </Link>
                </h3>

                <p>Proin vel magna dapibus risus interdum vulputate. Aenean ex turpis, faucibus eget massa ut, pharetra posuere est. Cras ac feugiat felis, vel pharetra tellus. Aliquam in rutrum enim. Suspendisse et accumsan elit, sit amet rutrum orci. Nam a arcu blandit magna commodo pharetra. Cras non nulla eleifend, sodales lacus id, maximus leo. Praesent a arcu dapibus, tempor tellus quis, luctus ipsum..</p>

                <Link
                className='button2 medBlue'
                paintDrip
                hex="#FF7900"
                duration={.75}
                to={'/spring-2020/' + introStory.slug}>
                  Watch Video
                </Link>

              </div>
            </div> */}

            <Link
            style={{
              backgroundImage: `url(${introStory.thumbnailIndex})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              marginBottom: '30px'
            }}
            className={"story-link " + introStory.storyType + " " + introStory.slug}
            paintDrip
            hex="#FF7900"
            duration={.75}
            to={'/spring-2020/' + introStory.slug}>
              <div className="story-text">
                <h3>{introStory.title}</h3>
              </div>
            </Link>

            {list}

          </div>
        </section>

        
          <section className="external">
            <a href="https://campaign.fullerton.edu/" target="_blank" className="itat external-block">
              <h3><img src={ITAT} alt="It Takes a Titan - he Campaign for Cal State Fullerton" style={{width: '400px'}} /></h3>
              {/* <h3>It Takes a Titan</h3> */}
              {/* <p className="sub-head">The Campaign for Cal State Fullerton</p> */}
              <p>The university’s first-ever comprehensive philanthropic campaign aims to elevate the potential of Titan students. The $200 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community.</p>
              <img src={ArrowRightCircle} alt="" className="arrow-right-circle" />
            </a>
            <a href="http://blog.fullerton.edu/" target="_blank" className="titan-voices external-block">
              <h3><img src={TitanVoices} alt="Titan Voices" style={{width: '250px'}}  /></h3>
              {/* <h3>Titan Voices</h3> */}
              <p className="sub-head">CSUF Reflections and Responses to the COVID-19 Pandemic</p>
              <p>Titan Voices is a collection of stories, student blog posts, photos and videos that chronicles the resilience of the Cal State Fullerton community during the novel coronavirus health crisis.</p>
              <img src={ArrowRightCircle} alt="" className="arrow-right-circle" />
            </a>
          </section>
      </Layout>
    );
  }
}